import dotenv from 'dotenv';

dotenv.config();

export const productionConfig = {
    token: `${process.env.VUE_APP_TEST_TOKEN}`,
    fantasyHost: `${process.env.VUE_APP_FANTASY_SERVICE}`,
    utilsHost: `${process.env.VUE_APP_UTILS_SERVICE}`,
    predictorHost: `${process.env.VUE_APP_PREDICTOR_HOST}`,
    freePlayPredictorHost :  `${process.env.VUE_APP_FREEPLAY_PREDICTOR_HOST}`,
    entryPortalHost: `${process.env.VUE_APP_ENTRY_PORTAL}`,
    vodacomUrl: `${process.env.VUE_APP_VODACOM_URL}`,
    upstreamIntegrationService: `${process.env.VUE_APP_UPSTREAM_SERVICE}`,
    proxyServiceUrl: `${process.env.VUE_APP_PROXY_SERVICE}`,
    hyveAwsUrl: `${process.env.VUE_APP_HYVE_AWS}`,
    hyveAwsApiKey: `${process.env.VUE_APP_HYVE_AWS_API_KEY}`,
    hyveAwsAccessKey: `${process.env.VUE_APP_HYVE_ACCESS_KEY}`,
    hyveAwsAccessSecret: `${process.env.VUE_APP_HYVE_ACCESS_SECRET}`,
    upstreamAisHost: `${process.env.VUE_APP_UPSTREAM_AIS_HOST}`,
}

export const stagingConfig = {
    token: `${process.env.VUE_APP_TEST_TOKEN}`,
    fantasyHost: `${process.env.VUE_APP_STAGING_FANTASY_SERVICE}`,
    utilsHost: `${process.env.VUE_APP_UTILS_SERVICE}`,
    predictorHost: `${process.env.VUE_APP_STAGING_PREDICTOR_HOST}`,
    freePlayPredictorHost :  `${process.env.VUE_APP_FREEPLAY_PREDICTOR_HOST}`,
    entryPortalHost: `${process.env.VUE_APP_STAGING_ENTRY_PORTAL}`,
    vodacomUrl: `${process.env.VUE_APP_STAGING_VODACOM_URL}`,
    upstreamIntegrationService: `${process.env.VUE_APP_STAGING_UPSTREAM_SERVICE}`,
    proxyServiceUrl: `${process.env.VUE_APP_PROXY_SERVICE}`,
    hyveAwsUrl: `${process.env.VUE_APP_HYVE_AWS}`,
    hyveAwsApiKey: `${process.env.VUE_APP_HYVE_AWS_API_KEY}`,
    hyveAwsAccessKey: `${process.env.VUE_APP_HYVE_ACCESS_KEY}`,
    hyveAwsAccessSecret: `${process.env.VUE_APP_HYVE_ACCESS_SECRET}`,
    upstreamAisHost: `${process.env.VUE_APP_UPSTREAM_AIS_HOST}`,
}

const environment = getEnvironment()

export const environmentConfig = getEnvObject();

function getEnvironment() {
    var base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'development'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return stagingConfig
    if (environment === 'production') return productionConfig
}
