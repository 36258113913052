<template>
  <div class="body-container" v-if="!isLoading">
    <div class="d-flex flex-row pt-2 rounds-header ">
      <strong>{{ translationsFormatter('upcoming_rounds_header_one') }}</strong>&nbsp;
      <div class="light-header-text">{{ translationsFormatter('upcoming_rounds_header_two') }}</div>
    </div>
    <div @click="routeToMyRounds()" class="d-flex flex-column align-items-center text-center pt-5"
         v-if="allRoundsEnteredMessageAlert">
      <img class="col-7" src="../../assets/boots-icon.png">
      <div class="pt-2 col-10">{{ translationsFormatter('upcoming_rounds_entered_all_rounds') }}</div>
    </div>
    <div @click="routeToPlay()" class="d-flex flex-column align-items-center text-center pt-5"
         v-if="noRoundsEnteredMessageAlert">
      <img class="col-7" src="../../assets/red-card-icon.png">
      <div class="pt-2 col-10">{{ translationsFormatter('upcoming_rounds_entered_no_rounds') }}</div>
    </div>
    <ActiveRound class="d-flex flex-column pt-2" v-for="(activeRound, index) in this.activeRounds" :key="index"
                 :activeRound="activeRound"/>
    <div class="mt-4 upcoming-fixtures-header">
      <strong>{{ translationsFormatter('upcoming_fixtures_header_title') }}</strong>
    </div>
    <UpcomingFixture class="d-flex flex-column" v-for="(inactiveRound, index) in this.upcomingFixtures" :key="index"
                     :inactiveRound="inactiveRound"/>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import ActiveRound from "@/views/components/ActiveRound";
import UpcomingFixture from "@/views/components/UpcomingFixture";
import GameDataService from "@/services/gameDataService";
import UserDataService from "@/services/userDataService";
import moment from "moment";
import Helpers from "@/helpers/helpers";

export default {
  name: "UpcomingRounds",
  components: {ActiveRound, UpcomingFixture},

  data() {
    return {
      allRoundsEnteredMessageAlert: false,
      noRoundsEnteredMessageAlert: false,
    }
  },
  computed: {
    ...mapState(['activeRounds', 'isLoading', 'upcomingFixtures', 'user']),
  },
  async beforeMount() {
    this.setIsLoading(true);

    const upcomingFixtures = await GameDataService.upcomingFixtures();
    const upcomingRounds = await GameDataService.activeRounds(this.user.msisdn);

    await upcomingRounds.data.asyncForEach(async (round) => {
      round.selections = {};
      if (round.entered) this.processRoundHasEntries(round)
    })
    this.setRoundsUserCanEnter(upcomingRounds.data);
    this.setUpcomingFixtures(upcomingFixtures.data);
    this.checkRoundsEntered();


    this.setIsLoading(false);
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setActiveRounds', 'setUpcomingStoreFixtures']),
   async processRoundHasEntries(round) {
      round.hasFullTeam = true;
      const userEntries = await UserDataService.getUserEntries(round.id, this.user.msisdn);
      const midfielders = []
      userEntries.data.forEach((entry) => {
        if (entry.position === "Forward") this.setPositionEntry(round, "forward", entry);
        if (entry.position === "Midfielder") midfielders.push(entry);
        if (entry.position === "Defence") this.setDefenceEntry(round, entry);
        if (entry.position === "Goalkeeper") this.setPositionEntry(round, "goalkeeper", entry)
      })
     this.setMidFielderEntries(round, midfielders)
    },
    setMidFielderEntries(round, midfielders) {
      this.setPositionEntry(round, 'midfielder1', midfielders[0]);
      this.setPositionEntry(round, 'midfielder2', midfielders[1])
    },
    setPositionEntry(round, position, entry) {
      round.selections[position] = entry
      round.selections[position].teamNames = entry.teamNames
    },
    setDefenceEntry(round, entry) {
      round.selections.defence = entry
      round.selections.defence.teamNames = entry.name.slice(0, 1)
    },

    routeToMyRounds() {
      this.$router.push('/my-rounds');
    },
    routeToPlay() {
      this.$router.push('/upcoming-rounds');
    },
    checkRoundsEntered() {
      this.activeRoundsArrayCount = this.activeRounds.length
      this.counter = 0;

      if (this.$route.name === 'UpcomingRounds') {
        for (const obj of this.activeRounds) {
          if (obj.hasFullTeam === true) this.counter++;
        }
        if (this.counter === this.activeRoundsArrayCount) {
          this.allRoundsEnteredMessageAlert = true;
        }
      }
      if (this.$route.name === 'MyRounds') {
        for (const obj of this.activeRounds) {
          if (obj.hasFullTeam === undefined) this.counter++;
        }
        if (this.counter === this.activeRoundsArrayCount) {
          this.noRoundsEnteredMessageAlert = true;
        }
      }
    },
    setUpcomingFixtures(upcomingFixtures) {
      const fixtures = []
      upcomingFixtures.forEach((upcomingLeagueRound) => {
        let entriesClosingTime = new Date(upcomingLeagueRound.matches[0].startAt);
        let timeNow = moment(new Date());
        let roundEnd = moment(entriesClosingTime)
        let timeDifferenceInDays = moment.duration(timeNow.diff(roundEnd));
        let daysUntilFixture = timeDifferenceInDays._data.days;
        let hoursUntilFixture = -timeDifferenceInDays._data.hours;

        fixtures.push({
          leagueName: upcomingLeagueRound.leagueName,
          leagueImgURL: this.findImageModule(upcomingLeagueRound.league),
          matches: this.setMatchesInUpcomingFixtures(upcomingLeagueRound.matches, upcomingLeagueRound.league),
          timeUntilRoundOpens: {
            days: daysUntilFixture,
            hours: hoursUntilFixture,
          },
        })
      })
      this.setUpcomingStoreFixtures(fixtures)
    },
    setMatchesInUpcomingFixtures(unsortedFixtures) {
      let emptyUpcomingFixtures = [];
      unsortedFixtures.forEach((inActiveMatchFixture) => {
        emptyUpcomingFixtures.push({
          homeTeam: {
            homeTeamName: Helpers.formatTextForAssetNames(inActiveMatchFixture.teamHomeFullName),
            homeBadgeImgURL: Helpers.findTeamImageModule(inActiveMatchFixture.teamHomeFullName)
          },
          awayTeam: {
            awayTeamName: Helpers.formatTextForAssetNames(inActiveMatchFixture.teamAwayFullName),
            awayBadgeImgURL: Helpers.findTeamImageModule(inActiveMatchFixture.teamAwayFullName)
          },
        })
      })
      return emptyUpcomingFixtures;
    },
    findImageModule(league, team) {
        if (!team) return Helpers.findLeagueImageModule(league);
        if (team) return Helpers.findTeamImageModule(team);
    },
    setRoundsUserCanEnter(upcomingRounds) {
      const rounds = []
      upcomingRounds.forEach((activeRoundsDataObject) => {
        let entriesClosingTime = new Date(activeRoundsDataObject.entriesCloseAt);
        let timeNow = moment(new Date());
        let roundEnd = moment(entriesClosingTime);
        let timeDifferenceInDays = moment.duration(timeNow.diff(roundEnd));
        let daysUntilFixture = timeDifferenceInDays._data.days;
        let hoursUntilFixture = -timeDifferenceInDays._data.hours;

        if (entriesClosingTime > new Date()) {
          rounds.push({
            isUpcomingRoundListExpanded: !!activeRoundsDataObject.hasFullTeam,
            hasFullTeam: activeRoundsDataObject.hasFullTeam,
            leagueAbbreviation: activeRoundsDataObject.matches[0].league,
            roundId: activeRoundsDataObject.id,
            leagueName: activeRoundsDataObject.name,
            selections: activeRoundsDataObject.selections,
            matches: this.setMatchesInRounds(activeRoundsDataObject.matches),
            leagueBadgeImgURL: this.findImageModule(activeRoundsDataObject.name),
            timeUntilRoundClose: {
              days: daysUntilFixture,
              hours: hoursUntilFixture,
            },
          })
        }
      })
      this.setActiveRounds(rounds)
    },
    setMatchesInRounds(unsortedUpcomingMatches) {
      let emptyUpcomingMatches = [];
      unsortedUpcomingMatches.forEach((matchFixture) => {
        emptyUpcomingMatches.push({
          homeTeam: {
            homeTeamName: matchFixture.teamHomeFullName,
            homeTeamBadgeImgURL: Helpers.findTeamImageModule(matchFixture.teamHomeFullName),
          },
          awayTeam: {
            awayTeamName: matchFixture.teamAwayFullName,
            awayTeamBadgeImgURL: Helpers.findTeamImageModule(matchFixture.teamAwayFullName),
          },
        })
      })
      return emptyUpcomingMatches;
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.path === '/my-rounds') {
        this.checkRoundsEntered();
        this.allRoundsEnteredMessageAlert = false
      }
      if (this.$route.path === '/upcoming-rounds') {
        this.checkRoundsEntered();
        this.noRoundsEnteredMessageAlert = false
      }
    },
  }
}
</script>

<style scoped>
.body-container {
  padding-bottom: 8vh;
  background-color: #e8e8e8;
  background-size: cover;
  overflow-y: scroll;
  overflow-x: hidden;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.rounds-header, .upcoming-fixtures-header {
  font-size: 14px;
}

.light-header-text {
  color: #939393;
}

</style>
