import {createRouter, createWebHistory} from 'vue-router'
import LandingPage from "../views/pages/LandingPage";
import LoginPage from "@/views/pages/LoginPage";
import SubmitOTP from "@/views/pages/SubmitOTP";
import TopPicks from "../views/pages/TopPicks";
import UpcomingRounds from "../views/pages/UpcomingRounds";
import TeamBuilder from "@/views/pages/TeamBuilder";
import TeamSelection from "@/views/pages/TeamSelection";
import MyResults from "../views/pages/MyResults";
import RoundLeaderboard from "@/views/pages/RoundLeaderboard";
import UserSettings from "@/views/pages/UserSettings";
import EditUsername from "@/views/pages/EditUsername";
import ManageNotifications from "@/views/pages/ManageNotifications";
import CancelPackage from "@/views/pages/CancelPackage";
import AutoTeamBuilder from "@/views/pages/AutoTeamBuilder";
import HowToPlay from "@/views/pages/HowToPlay";
import PrizesList from "@/views/pages/PrizesList";
import ConfirmSubscription from "@/views/pages/ConfirmSubscription";
import App from "@/App";
import {userAuthenticate} from "@/functions/userAuthenticate";
import TermsConditions from "@/views/pages/TermsConditions";
import FrequentlyAskedQuestions from "@/views/pages/FrequentlyAskedQuestions";

const routes = [
    {
        path: '/',
        component: App
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
        meta: {hideFooterMenu: true, hideNavBar: true},
    },
    {
        path: '/otp',
        name: 'SubmitOTP',
        component: SubmitOTP,
        meta: {hideFooterMenu: true, hideNavBar: true},
    },
    {
        path: '/subscription',
        name: 'ConfirmSubscription',
        component: ConfirmSubscription,
        meta: {hideFooterMenu: true, hideNavBar: true},
    },
    {
        path: '/profile',
        name: 'LandingPage',
        component: LandingPage,
    },
    {
        path: '/top-picks',
        name: 'TopPicks',
        component: TopPicks,
    },
    {
        path: '/my-rounds',
        name: 'MyRounds',
        component: UpcomingRounds,
    },
    {
        path: '/upcoming-rounds',
        name: 'UpcomingRounds',
        component: UpcomingRounds,
    },
    {
        path: '/team-builder',
        name: 'TeamBuilder',
        component: TeamBuilder,
        meta: {hideNavBar: true},
    },
    {
        path: '/team-selector',
        name: 'TeamSelection',
        component: TeamSelection,
        meta: {hideNavBar: true},
    },
    {
        path: '/my-results',
        name: 'MyResults',
        component: MyResults,
    },
    {
        path: '/round-leaderboard/:roundId',
        name: 'RoundLeaderboard',
        component: RoundLeaderboard,
    },
    {
        path: '/auto-team-builder',
        name: 'AutoTeamBuilder',
        component: AutoTeamBuilder,
    },
    {
        path: '/settings',
        name: 'UserSettings',
        component: UserSettings,
        children: [
            {
                path: 'username',
                name: 'EditUsername',
                component: EditUsername,
            },
            {
                path: 'notifications',
                name: 'ManageNotifications',
                component: ManageNotifications,
            },
            {
                path: 'unsubscribe',
                name: 'CancelPackage',
                component: CancelPackage,
            },
        ]
    },
    {
        path: '/how-to-play',
        name: 'HowToPlay',
        component: HowToPlay,
    },
    {
        path: '/prizes',
        name: 'PrizesList',
        component: PrizesList,
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsConditions',
        component: TermsConditions,
        meta: {hideFooterMenu: true, hideNavBar: true},
    },
    {
        path: '/frequently-asked-questions',
        name: 'FrequentlyAskedQuestions',
        component: FrequentlyAskedQuestions,
        meta: {hideFooterMenu: true, hideNavBar: true},
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next);
})

export default router
