<template>
  <div>
    <div class="pt-2 pb-2 text-center header-text">
      {{ translationsFormatter('prizes_header') }}
    </div>
    <div class="p-2 text-center promo-text">
      {{ translationsFormatter('prizes_promo_info') }}
    </div>
    <div class=" d-flex flex-row col-12 mt-3 pt-2 pb-2 s secondary-bg-colour primary-text align-items-center prize-container">
      <img class="prizes-place-icon" :src="require('../../../assets/prizes-first.png')"/>
      <div class="col-10 d-flex flex-column">
        <div class=" how-it-works-text">{{ translationsFormatter('prizes_first_place_header') }}</div>
        <div class="grand-prize-amount">{{ translationsFormatter('prizes_first_place_airtime_amount') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "VodacomEnglishPrizes",
  computed: {
    ...mapState(['isLoading', 'brandConfig'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
  },
}
</script>

<style scoped>
.header-text {
  font-size: 1.2rem;
  font-weight: bold;
}

.promo-text {
  font-weight: 700;
  font-size: 15px;
}

.grand-prize-container {
  position: relative;
  border-radius: 10px;
  height: max-content;
  padding-left: 60px;
  padding-bottom: 15px;
}

.grand-prizes-header {
  font-weight: 600;
  font-size: 17px;
}

.grand-prize-amount {
  font-size: 14px;
}

.how-it-works-container {
  border-top: 1px solid #FFFFFF;
}

.how-it-works-text {
  font-weight: 600;
  font-size: 14px;
}

.trophy-icon {
  position: absolute;
  height: 40px;
  left: 4%;
  top: 10%;
}

.perfect-pick-explanation-text {
  padding-left: 5px;
  font-size: 11px;
}

.perfect-pick-icon {
  height: 28px;
}

.prize-container {
  border-radius: 10px;
  height: max-content;
}

.prizes-place-icon {
  margin-left: 12px;
  margin-right: 21px;
  height: 40px;
}

table {
  border: 1px solid black;
}

</style>
