import UserDataService from "@/services/userDataService";
import store from "../store";
import {environmentConfig} from "@/config/enviromentConfig";
import upstreamHeaderEnrichedLogin from "@/functions/upstreamHeaderEnrichedLogin";
import LoginService from "@/services/loginService";

export async function userAuthenticate(to, from, next) {
    if (checkUserState()) return next();
    if (to.fullPath === '/login' || to.fullPath === '/otp') return next();
    store.commit('setIsLoading', true);

    const urlParams = new URLSearchParams(window.location.search);
    const localStorageToken = localStorage.getItem('token');
    const encryptedMsisdn = urlParams.get('encryptedMsisdn');
    const hasUuid = urlParams.has('uuid');
    const uuid = urlParams.get('uuid');
    if (hasUuid) store.commit('setHeaderEnrichmentAttempted', true);
    const fantasyToken = urlParams.get('fantasyToken') ? urlParams.get('fantasyToken') : localStorageToken ? localStorageToken : undefined;
    if (encryptedMsisdn) await encryptedMsisdnAuth(encryptedMsisdn, next, to);
    if (fantasyToken) return await setUserData(fantasyToken, to, next);
    
    attemptEncryptionRedirect(uuid);
    if (uuid) await upstreamEnrichedUserLogin(uuid, next, to);
    else redirectToLogin(next);
}

async function setUserData(token, to, next) {
    const user = await UserDataService.getUserStatus(`${token}`);
    if (!user) return redirectToLogin(next);

    localStorage.setItem('token', token);
    const stats = await UserDataService.getUserStats(user.data.msisdn);
    store.commit('setUser', user.data);
    store.commit('setUserStats', stats.data);

    if (user.data.grantAccess) {
        await LoginService.trackLogin(user.data.msisdn, store.state.brandConfig.revenuePartnerId);
        return redirectToProfile(next);
    }
    if (user.data.token === 'invalidToken') return invalidTokenRedirect(next);
    if (!user.data.grantAccess && stats) return redirectToSubscription(next);
    else return redirectToLogin(next);
}

function checkUserState() {
    if (store.state.termsAndConditionsOpened) return true;
    if (store.state.frequentlyAskedQuestionsOpened) return true;
    if (store.state.user && store.state.userLandingStats) return true;
    return false
}

async function encryptedMsisdnAuth(encryptedMsisdn, next, to) {
    const headerEnrichedLoginToken = await UserDataService.headerEnrichedLogin(encryptedMsisdn);
    await setUserData(headerEnrichedLoginToken, to, next);
    store.commit('setIsLoading', false);
}

function attemptEncryptionRedirect(uuid) {
    const encryptionConfig = store.state.brandConfig.encryptionKey;
    if (!uuid && !store.state.headerEnrichmentAttempted && isUpstreamHeaderEnrichemntBrand(store.state.brandConfig.brand)) window.open(`${encryptionConfig.enrichmentUrl}`, '_self');
}

async function upstreamEnrichedUserLogin(uuid, next, to) {
    const msisdn = await upstreamHeaderEnrichedLogin(uuid);
    const user = await UserDataService.getUser(msisdn);
    if (user.data.token) {
        await UserDataService.updateToken(user.data.token);
        const newUser = await UserDataService.getUser(msisdn);
        await setUserData(newUser.data.token, to, next);
    }
}

function redirectToLogin(next) {
    store.commit('setIsLoading', false);
    if (store.state.brandConfig.brand === 'vc-za-en') return window.open(`${environmentConfig.entryPortalHost}`, '_self');
    if (store.state.brandConfig.brand === 'saudi-uae-en') return window.open(`https://demo.fantasy.penrosegaming.com/?fantasyToken=0F3D9469-D581-4511-ABA1-0FF65259B54F`, '_self');
    if (store.state.brandConfig.brand === 'ais-tai-en') return window.open(`https://ais.fantasy.penrosegaming.com/?fantasyToken=0F3D9469-D581-4511-ABA1-0FF65259B54F`, '_self');
    if (store.state.brandConfig.brand === 'dtac-tai-en') return window.open(`https://dtac.fantasy.penrosegaming.com/?fantasyToken=0F3D9469-D581-4511-ABA1-0FF65259B54F`, '_self');
    if (store.state.brandConfig.brand === 'dtac-tai-tha') return window.open(`https://dtac.fantasy.penrosegaming.com/?fantasyToken=0F3D9469-D581-4511-ABA1-0FF65259B54F`, '_self');
    else return next(`/login`);
}
function redirectToProfile(next) {
    store.commit('setIsLoading', false);
    return next('/profile');
}

function invalidTokenRedirect(next) {
    localStorage.removeItem("token");
    store.commit('setUser', undefined);
    store.commit('setUserStats', undefined);
    if (store.state.brandConfig.brand === 'vc-za-en') return window.open(`${environmentConfig.entryPortalHost}`, '_self');
    else redirectToLogin(next);
}

function redirectToSubscription(next) {
    if (store.state.brandConfig.brand === 'vc-za-en') return window.open(`${environmentConfig.entryPortalHost}`, '_self');
    else {
        store.commit('setIsLoading', false);
        return next(`/subscription`);
    }
}

function isUpstreamHeaderEnrichemntBrand(currentBrand) {
    if (currentBrand === 'demo-um-en') return false
    if (currentBrand === 'vc-za-en') return false
    if (currentBrand === 'ais-tai-en') return false
    if (currentBrand === 'dtac-tai-en') return false
    if (currentBrand === 'dtac-tai-tha') return false
    if (currentBrand === 'ais-tai-tha') return false
    return true
}


