<template>
  <div class="d-flex flex-column body-container" v-if="!isLoading">
    <div class="d-flex flex-row justify-content-start p-2 top-picks-header">
      {{ translationsFormatter('top_picks_header') }}
    </div>
    <div class="league-container" v-for="(league, index) in this.leagues" :key="index">
      <div class="text-center" v-if="!league.topPlayers">
        <img class="col-7 mt-5 mb-2" src="../../assets/boots-icon.png">
        <div>The Top Picks will update once the 1st round has resulted & the teams are in</div>
      </div>
      <div v-if="league.topPlayers" class="league-logo-container">
        <img class="league-logo-img" :src="this.getLeagueBadge(league.leagueAbbreviation)">
        <div class="league-title">{{ league.leagueName.charAt(0).toUpperCase() + league.leagueName.slice(1) }}</div>
      </div>
      <div class="league-players-container">
        <div class="player-box" v-for="(player) in league.topPlayers">
          <img class="player-jersey" :src="Helpers.findJerseyImageModule(player.teamNames[0])">
          <div class="top-pick-player-name text-center">{{ player.name }}</div>
          <div class="col-10 pt-1 top-pick-player-position">{{ player.position }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import GameDataService from "@/services/gameDataService";

export default {
  name: "TopPicks",
  data() {
    return {
      leagues: [],
    }
  },
  async beforeMount() {
    this.setIsLoading(true);
    const leagues = await this.getLeagues();
    await this.setLeaguesAndTopPlayers(leagues)
    this.setIsLoading(false);
  },
  computed: {
    ...mapState(['isLoading'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    async getLeagues() {
      const returnedLeaguesResponse = (await GameDataService.getLeagues()).data;
      return returnedLeaguesResponse.splice(-2)
    },
    async getTopPlayersInLeague(leagueAbbreviation, season) {
      return (await GameDataService.getLeagueTopPicks(leagueAbbreviation, season)).data;
    },
    getLeagueBadge(leagueAbbreviation) {
      try {
        return require(`../../assets/leagues/logos/${leagueAbbreviation}-logo-colour.png`)
      } catch (e) {
        return require(`@/assets/default-badge.png`)
      }
    },
    setLeaguesAndTopPlayers(leagues) {
      leagues.asyncForEach(async (league) => {
        const playersResponse = await this.getTopPlayersInLeague(league.ref, league.seasonRef)
        const top5Players = playersResponse ? playersResponse.players.slice(0, 5) : ""
        this.leagues.push({
          leagueName: league.name,
          leagueAbbreviation: league.ref,
          season: league.seasonRef,
          topPlayers: top5Players
        })
      })
    },
  }
}
</script>

<style scoped>

.body-container {
  background-color: #e8e8e8;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 5vh;
  background-size: 100%;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.league-container {
  display: flex;
  flex-direction: column;
}

.player-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  border-radius: 15px;
  width: 30%;
  padding: 5px;
  margin: 5px;
}

.player-jersey {
  height: 40px;
}

.league-logo-container {
  display: flex;
  align-items: center;
}

.league-players-container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.top-picks-header {
  font-weight: bold;
}

.league-logo-img {
  height: 30px;
  width: 30px;
}

.league-title {
  padding-left: 5px;
  font-weight: bold;
}

.top-pick-player-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 8px;
  padding: 0.25rem;
}

.top-pick-player-position {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  font-weight: 700;
}

@media screen and (max-width: 388px) {
  .player-box {
    width: 28%;
  }
}
</style>
