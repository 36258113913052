<template>
  <div class="d-flex flex-column body-container" v-if="!isLoading">
    <div class="d-flex flex-row p-2 my-results-header">
      {{ translationsFormatter('my_results_header_title') }}
    </div>
    <div class="d-flex align-items-center justify-content-center winnings-container">
      <img :src="fetchBanner()" class="main-banner">
      <div @click="routeToPlay()" v-if="!userStats.airtimeWon && !brandConfig.isRandomDrawAwarded" class="text-center play-circle">
        <div class="play-circle-text">
          {{ translationsFormatter('my_results_play_circle') }}
        </div>
      </div>
      <div v-if="userStats.airtimeWon && !brandConfig.isRandomDrawAwarded" class="user-winnings">
        <div class="text-center p-1 user-winnings-header primary-text">
          {{ translationsFormatter('my_results_winnings_header') }}
        </div>
        <div class="d-flex flex-row col-6 airtime-winnings-container">
          <div class="text-center col-2 winner-vertical-tag secondary-text">
            {{ translationsFormatter('my_results_airtime_won_vertical_tag') }}
          </div>
          <div class="d-flex flex-column col-10 airtime-won-tag primary-bg-colour primary-text">
            <div class="d-flex flex-row airtime-won-amount-container">
              <div class="col-1 currency-acronym">{{
                  translationsFormatter('my_results_airtime_won_currency_acronym')
                }}
              </div>
              <div class="col-11 text-center airtime-won-amount">{{ userStats.airtimeWon }}</div>
            </div>
            <div class="text-center airtime-text">{{ translationsFormatter('my_results_airtime_won_label') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column text-center align-items-center pt-3" v-if="showNoResultsMessage">
      <img class="col-7" src="../../assets/flag-icon.png">
      <div class="col-10">No historic round results</div>
    </div>
    <div class="p-1">
      <table class="table-results-container table-borderless" v-if="!showNoResultsMessage">
        <thead>
        <tr class="">
          <th scope="col">{{ translationsFormatter('my_results_table_round_column_header') }}</th>
          <th scope="col"></th>
          <th scope="col">{{ translationsFormatter('my_results_table_position_column_header') }}</th>
          <th scope="col">{{ translationsFormatter('my_results_table_points_column_header') }}</th>
          <th v-if="userStats.airtimeWon && !brandConfig.isRandomDrawAwarded" scope="col">{{
              translationsFormatter('my_results_table_airtime_column_header')
            }}
          </th>
        </tr>
        </thead>
        <tbody class="justify-content-center">
        <tr @click="roundResultClicked(round)" v-for="round in resultedRounds" :key="round.id "
            :class="round.position < 201 ? 'primary-bg-colour primary-text fw-bold' : 'table-row'">
          <td :class="round.position >= 201 || !round.position ? 'border-default' : 'border-winner'"><img
              class="league-logo-img" :src="Helpers.findLeagueImageModule(round.name)"></td>
          <td :class="round.position >= 201 || !round.position ? 'border-default' : 'border-winner'"
              class="col-5 league-name text-center align-middle"> {{ this.formatRoundDate(round.endAt) }}
          </td>
          <td :class="round.position >= 201 || !round.position ? 'border-default' : 'border-winner'"
              class="col-2 text-center align-middle"> {{ round.position }}
          </td>
          <td :class="round.position >= 201 || !round.position ? 'border-default' : 'border-winner'"
              class="col-2 text-center align-middle"> {{ round.points }}
          </td>
          <td v-if="userStats.airtimeWon && !brandConfig.isRandomDrawAwarded"
              :class="round.position >= 201 || !round.position ? 'border-default' : 'border-winner'"
              class="col-2 text-center align-middle">{{ this.airtimeAwarded(round.position) }}
          </td>
          <td :class="round.position >= 201 || !round.position ? 'border-default' : 'border-winner'" class="col-2 text-center align-middle">
            <img class="right-arrow-table" :src="this.defaultWinnerArrow(round.position)">
          </td>
        </tr>
        </tbody>
      </table>
      <div class="mb-5">
        <div class="d-flex flex-column mt-3 align-items-center col-12">
          <img v-if="resultedRoundsPageNumber === 1" @click="paginateResults('+')" class="right-pagination-arrow primary-bg-colour" src="../../assets/right-arrow-white.png">
        </div>
        <div class="d-flex flex-row justify-content-around">
          <img v-if="resultedRoundsPageNumber !== 1" @click="paginateResults('-')" class="left-pagination-arrow primary-bg-colour" src="../../assets/right-arrow-white.png">
          <img v-if="resultedRoundsPageNumber !== 1" @click="paginateResults('+')" class="right-pagination-arrow primary-bg-colour" src="../../assets/right-arrow-white.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import GameDataService from "@/services/gameDataService";
import moment from "moment";


export default {
  name: "MyResults",
  data() {
    return {
      resultedRounds: undefined,
      userStats: undefined,
      showNoResultsMessage: false,
      resultedRoundsPageNumber: 1,
    }
  },
  async beforeMount() {
    this.setIsLoading(true);
    this.userStats = this.userLandingStats.userStats;
    await this.filterUserResultedRounds()
    this.checkIfUserHasNoResults();
    this.setIsLoading(false);
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'userLandingStats', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setSelectedRound']),
    moment: function () {
      return moment();
    },
    routeToPlay() {
      this.$router.push('/upcoming-rounds');
    },
    checkIfUserHasNoResults() {
      if (this.resultedRounds.length === 0) {
        this.showNoResultsMessage = true
      }
    },
    fetchBanner() {
      return require(`../../brand/${this.brandConfig.brand}/assets/results-main-banner.png`)
    },
    airtimeAwarded(position) {
      if (position < 201) {
        return 'R10'
      } else {
        return '-'
      }
    },
    formatRoundDate(date) {
      return moment(date).format('Do MMMM YY')
    },
    defaultWinnerArrow(position) {
      if (position < 201) {
        return require(`../../assets/right-arrow-white.png`)
      } else {
        return require(`../../assets/right-arrow-grey.png`)
      }
    },
    roundResultClicked(round) {
      this.$router.push({name: 'RoundLeaderboard', params: {roundId: round.id}})
    },
    async paginateResults(symbol) {
      this.setIsLoading(true)
      this.adjustResultedRoundsPageNumber(symbol)
      const {msisdn} = this.user;
      const resultedRoundsResponse = await GameDataService.getResultedRounds(msisdn, this.resultedRoundsPageNumber, 10);
      this.resultedRounds = resultedRoundsResponse.data;
      await this.filterUserResultedRounds()
      this.setIsLoading(false)
    },
    adjustResultedRoundsPageNumber(symbol) {
      if (symbol === '+') {
        this.resultedRoundsPageNumber = this.resultedRoundsPageNumber + 1
      } else {
        this.resultedRoundsPageNumber = this.resultedRoundsPageNumber - 1
      }
    },
    async filterUserResultedRounds() {
      const {msisdn} = this.user;
      const {data} = await GameDataService.getResultedRounds(msisdn, this.resultedRoundsPageNumber, 10);
      this.resultedRounds = data.filter(round => round.position !== undefined);
    },
  },
}
</script>

<style scoped>
.body-container {
  background-size: cover;
  background-color: #e8e8e8;
}

.my-results-header {
  font-weight: bold;
}

.winnings-container {
  position: relative;
}

.main-banner {
  width: 100%;
}

.play-circle {
  position: absolute;
  z-index: 1;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: #05a105;
  color: white;
}

.play-circle-text {
  font-weight: bold;
  font-size: 36px;
  margin-top: 25%;
}

.user-winnings {
  position: absolute;
  z-index: 1;
}

.user-winnings-header {
  font-size: 22px;
  font-weight: 700;
  text-shadow: black 0px 0px 4px;
}

.airtime-winnings-container {
  margin-left: 30%;
  margin-bottom: 6%;
}

.winner-vertical-tag {
  border-radius: 0 12px 12px 0;
  background-color: white;
  font-size: 12px;
  font-weight: bold;
  writing-mode: vertical-lr;
  -webkit-transform: rotate(-180deg);
}

.airtime-won-tag {
  font-weight: bold;
  border: 3px solid;
  border-radius: 0 16px 16px 0;
}

.airtime-won-amount-container {
  height: 33px;
}

.airtime-won-amount {
  font-size: 30px;
  margin-top: -7px;
}

.currency-acronym {
  padding-left: 4%;
  margin-top: -4px;
  font-size: 18px;
}

.airtime-text {
  font-size: 12px;
}

.table-results-container {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 3px;
}

thead {
  font-size: 10px;
  font-weight: lighter;
  color: #676060;
}

.league-name {
  font-size: 10px;
}

.table-row {
  background-color: #e7e7e7;
  font-weight: 700;
  height: 45px;
}

.border-winner {
  height: 45px;
}

.league-logo-img {
  height: 30px;
}

td:first-child {
  border-right-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.right-arrow-table {
  height: 20px;
}

.border-default {
  border: .5px solid #e7e7e7;
}

.right-pagination-arrow, .left-pagination-arrow {
  padding: 10px;
  width: 30px;
}

.left-pagination-arrow {
  transform: rotate(-0.50turn);
}

@media (max-width: 423px) {
  .league-name {
    font-size: 9px;
    font-weight: 700;
    text-align: center;
  }
}

@media (max-width: 395px) {
  .play-circle {
    width: 95px;
    height: 95px;
  }
}


</style>
