<template>
  <div class="body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column">
      <div class="pt-2 my-profile-header-text">
        <p>{{ translationsFormatter('profile_header') }}</p>
      </div>
      <div class="d-flex align-items-center justify-content-center my-winnings-container">
        <img :src="this.fetchBanner()" class="main-banner">
        <div v-if="userStats.userAirtimeWon && !brandConfig.isRandomDrawAwarded"
             class="text-center my-winnings-circle primary-border-colour secondary-text">
          <div class="currency-acronym-text">{{ translationsFormatter('profile_currency_acronym') }}</div>
          <div class="my-winnings-circle-airtime-amount-text">{{ userStats.userAirtimeWon }}</div>
          <div class="my-winnings-circle-airtime-won-text">{{ translationsFormatter('profile_airtime_won') }}</div>
        </div>
        <div @click="routeToPlay" v-if="!userStats.userAirtimeWon && !brandConfig.isRandomDrawAwarded"
             class="text-center my-profile-play-circle">
          <div class="my-profile-play-circle-text primary-text"><strong>{{ translationsFormatter('profile_play') }}</strong>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-around text-center p-2">
        <div
            class="d-flex flex-column flex-wrap col-4 total-season-points-container primary-bg-colour primary-text justify-content-center">
          <div class="p-2 total-season-points-text">
            {{ translationsFormatter('profile_total_season_points_header') }}
          </div>
          <div class="flex-wrap pt-2 total-season-points-result">
            {{ userStats.userTotalSeasonPoints }}
          </div>
        </div>
        <div
            class="d-flex flex-column flex-wrap col-4 rounds-entered-container primary-bg-colour primary-text justify-content-center">
          <div class="rounds-entered-text p-2">
            {{ translationsFormatter('profile_rounds_entered_header') }}
          </div>
          <div class=" pt-2 rounds-entered-results">
            {{ userStats.userRoundsEntered }}
          </div>
        </div>
        <div
            class="d-flex col-4 data-won-container primary-bg-colour primary-text d-flex flex-wrap flex-column justify-content-center">
          <div class="data-won-text p-2">
            {{ translationsFormatter('profile_top_hundred_header') }}
          </div>
          <div class="data-won-results">
            {{ translationsFormatter('profile_top_two_hundred_result', [userStats.totalTopHundred]) }}
          </div>
        </div>
      </div>
    </div>
    <div class="text-center auto-team-builder-button-container">
      <button @click="autoTeamBuilderButtonClicked" class="auto-team-builder-button">
        {{ translationsFormatter('profile_auto_team_builder_button') }}
      </button>
      <div v-if="autoTeamBuilderPopUp" class="flex-wrap p-2 auto-team-builder-popup-container">
        <strong>{{
            translationsFormatter('profile_auto_team_builder_popup_one')
          }}</strong>{{ translationsFormatter('profile_auto_team_builder_popup_two') }}
        <div @click="autoTeamBuilderCancelButton" class="close-auto-team-builder-popup">
          <img src="../../assets/pop-up-cross.png">
        </div>
      </div>
    </div>
    <div class="d-flex flex-column mt-2 top-managers-container">
      <div class=" mt-2 top-managers-container">
        <div class="top-managers-text">
          {{ translationsFormatter('profile_top_managers_header') }}
        </div>
        <div class="d-flex flex-row justify-content-between p-1 text-center pt-2">
          <div class="flex-column align-items-center text-center manager-div col-2" v-for="(manager) in topManagers"
               :key="manager.id">
            <img :src="require(`../../brand/${brandConfig.brand}/assets/user-icon.png`)"/>
            <p class="pt-1 top-managers-username">{{ manager.username }}</p>
            <p class="top-managers-ranking">{{ manager.rank }}</p>
          </div>
        </div>
        <div class="mt-2 pt-1 mb-5 my-top-picks-container">
          <div class="my-top-picks-text">
            {{ translationsFormatter('profile_my_top_picks_header') }}
          </div>
          <div class="d-flex flex-row justify-content-between p-1 text-center pt-2 my-top-picks-ranking-container">
            <div class="flex-column align-items-center text-center col-2 top-picks-div"
                 v-for="(player) in userTopPickedPlayers" :key="player.id">
              <img :src="Helpers.findJerseyImageModule(player.playerClub)">
              <p class="pt-1 top-picks-name">{{ player.playerName }}</p>
              <p class="top-picks-ranking">{{ player.rank }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
  name: "LandingPage",
  data() {
    return {
      autoTeamBuilderPopUp: true,
      userStats: {},
      topManagers: [],
      userTopPickedPlayers: [],
      userStatsFontSize: '35px',
    }
  },
  beforeMount() {
    this.setUserData();
    this.setTopManagers();
    this.setUserTopPickedPlayers();
    this.setFontSize();
  },
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'userLandingStats', 'user']),
  },
  methods: {
    ...mapMutations(['setRoundsEntered', 'setIsLoading', 'setUserStats']),
    fetchBanner() {
      if (this.brandConfig) return require(`../../brand/${this.brandConfig.brand}/assets/main-banner.png`)
    },
    setUserData() {
      this.userStats.userTotalSeasonPoints = this.userLandingStats.userStats.totalSeasonPoints;
      this.userStats.userRoundsEntered = this.userLandingStats.userStats.roundsPreviouslyEntered;
      this.userStats.totalTopHundred = this.userLandingStats.userStats.totalTopHundred;
      this.userStats.userAirtimeWon = this.userLandingStats.userStats.airtimeWon;
      this.setRoundsEntered(this.userLandingStats.userStats.roundsEntered);
    },
    setTopManagers() {
      this.topManagers = this.userLandingStats.topManagers;
    },
    setUserTopPickedPlayers() {
      this.userTopPickedPlayers = this.userLandingStats.userTopPicks;
      if (this.userTopPickedPlayers.length > 5) {
        this.userTopPickedPlayers.pop();
      }
      this.userTopPickedPlayers.sort(function (a, b) {
        return parseFloat(a.rank) - parseFloat(b.rank);
      });
    },
    setFontSize() {
      let totalSeasonPointsLength = this.userStats.userTotalSeasonPoints.toString().length;
      if (totalSeasonPointsLength >= 5) {
        this.userStatsFontSize = '25px'
      }
    },
    autoTeamBuilderButtonClicked() {
      this.$router.push({path: '/auto-team-builder'});
    },
    autoTeamBuilderCancelButton() {
      this.autoTeamBuilderPopUp = !this.autoTeamBuilderPopUp;
    },
    routeToPlay() {
      this.$router.push({path: '/upcoming-rounds'});
    },
  },
}
</script>

<style scoped>

.body-container {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 32vh;
  background-size: cover;
  background-color: #e8e8e8;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.my-profile-header-text {
  height: 5vh;
  font-size: 13px;
  font-weight: bold;
}

.main-banner {
  width: 100%;
}

.my-winnings-container {
  height: 80%;
  position: relative;
}

.my-winnings-circle {
  position: absolute;
  z-index: 1;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: solid 5px;
}

.my-profile-play-circle {
  position: absolute;
  z-index: 1;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #05a105;
  color: white;
}

.my-winnings-circle-airtime-amount-text {
  padding-top: 24px;
  font-size: 34px;
  font-weight: 700;
}

.my-winnings-circle-airtime-won-text {
  font-weight: 750;
  font-size: 12px;
  display: flex;
  justify-content: center;
}

.my-profile-play-circle-text {
  font-size: 40px;
  margin-top: 25%;
}

.currency-acronym-text {
  position: absolute;
  left: 4%;
  top: 24%;
  font-weight: 700;
  font-size: 20px;
}

.total-season-points-container {
  height: 100px;
  margin-right: 7px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 10px;
}

.total-season-points-text, .rounds-entered-text, .data-won-text {
  height: 30px
}

.total-season-points-result, .rounds-entered-results, .data-won-results {
  font-size: v-bind('userStatsFontSize')
}

.data-won-results {
  padding-top: 10px;
}

.rounds-entered-container {
  height: 100px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 10px;
}

.data-won-container {
  height: 100px;
  border-radius: 10px;
  margin-left: 7px;
  font-weight: bold;
  font-size: 10px;
}

.auto-team-builder-button {
  font-size: 14px;
  color: white;
  background-color: green;
  width: 100%;
  border-radius: 10px;
  height: 35px;
  border: 0;
}

.auto-team-builder-popup-container {
  position: relative;
  color: cornflowerblue;
  background-color: white;
  min-height: 100%;
  font-size: 10px;
  border-radius: 5px;
}

.close-auto-team-builder-popup img {
  position: absolute;
  width: 6%;
  left: 96%;
  top: -18%;
}

.top-managers-container, .my-top-picks-container {
  color: #FFFFFF;
  background-color: #464646;
  border-radius: 10px;
  height: 140px;
}

.top-managers-text, .my-top-picks-text {
  font-weight: bold;
  margin-left: 10px;
  margin-top: 20px;
}

.my-top-picks-ranking-container img {
  height: 40px
}

.manager-div img, .top-picks-div img {
  height: 40px;
  width: 40px;
}

.top-managers-username, .top-picks-name {
  font-size: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 25%;
}

.top-managers-ranking, .top-picks-ranking {
  margin-top: -12px;
  font-size: 9px;
}
</style>
